export default {
  name: 'home',
  components: {
  },
  data() {
    return {
      hasAgreed: false
    }
  },
  methods: {
    loadNextPage() {
      this.$router.push('/rules')
    }
  }
}